@import "../../../colours";
@import "../../../mixins";

.case-record-list-screen {

  .case-record-item {
    transition: background-color 200ms;

    &:hover {
      background-color: $grey-xlight;
    }

    .title-area {
      display: flex;
      align-items: center;

      .new-badge {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        display: block;
        background-color: $red-primary;
        margin-right: 10px;
        flex-shrink: 0;
      }

      .title-area-content {
        display: block;

        .case-record-title {
          font-size: large;
          font-weight: bold;
        }
      }
    }

  }

}