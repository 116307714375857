.reflection-settings-modal {
  .setting-option {
    display: flex;
    align-items: center;

    .preference-switch {
      flex-shrink: 0;
    }

    .preference-content {
      flex : 1 1;
      margin-left: 10px;
    }
  }
}