@import "../../colours";
@import "../../mixins";

.pdf-viewer-modal {
  background: #FFFFFF;
  color: $text-colour;
  display: flex;
  flex-direction: column;
  position: fixed;
  inset: 0;
  z-index: 9999; /* Force above Navigation Bar */
  animation-name: pdf-viewer-modal-did-appear;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;

  .dismissing {
    animation-name: pdf-viewer-modal-did-dismiss;
    animation-play-state: running;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  .pdf-viewer-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.4rem 0;
    border-bottom: 1px solid $grey-light;

    .pdf-viewer-header-title {
      font-size: large;
      font-weight: bold;
      margin-left: 0.8rem;
    }

    .pdf-viewer-header-space {
      flex: 1 1;
    }

    .pdf-viewer-header-paginator {
      text-align: center;
      display: flex;
      align-items: center;

      .paginator-button {
        width: 2.4rem;
        height: 2.4rem;
        border: 1px solid $border-xlight;
        border-radius: 5px;
        cursor: pointer;
        @include background-image-centre;

        &:hover {
          background-color: $grey-light;
        }
      }

      .paginator-page-display {
        min-width: 2.4rem;
        font-weight: bold;
        margin: 0 10px;
      }
    }

    .pdf-viewer-header-action {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.8rem;
      @include background-image-centre;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: $grey-light;
      }
    }

  }

  .pdf-content {
    flex: 1 1;
    text-align: center;
    overflow: auto;

    .pdf-container {
      width: auto;
      display: inline-block;
    }

  }

  @keyframes pdf-viewer-modal-did-appear {
    0% { opacity : 0 }
    100% { opacity : 1 }
  }

  @keyframes pdf-viewer-modal-did-dismiss {
    0% { opacity : 1 }
    100% { opacity: 0 }
  }

}