@import "../../../mixins";
@import "../../../colours";

.organisation-list-screen {

  .organisation-item {
    display: flex;
    align-items: center;

    .org-icon {
      width: 60px;
      height: 60px;
      border-radius: 5px;
      @include background-image-centre;
      display: block;
      border: 1px solid $grey-light;
      background-color: $background-primary;
    }

    .org-content {
      flex: 1 1;
      display: block;
      margin-left: 10px;

      .org-title {
        display: block;
        font-size: large;
        font-weight: bold;
      }
    }

  }

}