@import "../../colours";
@import "../../mixins";

.password-validator {

  .validation-check {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    &.fail, &.text-bg-danger {
      .text {
        font-weight: bold;
      }
    }

    .icon {
      width: 1.2em;
      height: 1.2em;
      //border-radius: 50%;
      //background-color: $background-primary;
      @include background-image-centre;
      margin-right: 10px;
    }

    .text {
      flex: 1 1;
      font-size: small;
    }

  }

}