@import "../../../colours";
@import  "../../../mixins";

.secure-register-screen {

  .secure-login-graphic-phrase {
    height: 180px;
    background: rgb(102,0,205);
    background: linear-gradient(145deg, $secureLoginStart 0%, $secureLoginEnd 100%);
    position: relative;

    .secure-login-overlay {
      inset: 0;
      position: absolute;
      @include background-image-centre;
    }
  }

  .btn-secure {
    background: rgb(102,0,205);
    background: linear-gradient(145deg, $secureLoginStart 0%, $secureLoginEnd 100%);
    color: $text-colour-inverse;
    display: flex;
    align-items: center;
    margin: auto;

    .icon {
      width: 1.4rem;
      height: 1.4rem;
      display: inline-block;
      @include background-image-centre;
      margin-right: 5px;
    }
  }

}