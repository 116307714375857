@import "../../mixins";
@import "../../colours";

$screen-title-height: 3.6rem;
$screen-title-text-height: 1.4em;

.screen-title {
  height: $screen-title-text-height;
  display: block;
  overflow: hidden;
  font-size: xx-large;

  .title-container {
    display: flex;
    font-weight: 800;
    align-items: center;

    .screen-title-text {
      flex: 1 1;
    }
  }

  .action-icon, .action-space {
    width: 2.4rem;
    height: 2.4rem;
    display: none;
    border: 0;
    background: transparent;
    margin: 0 10px;

    &.always-display {
      display: block;
    }
  }

  .action-icon {
    @include background-image-centre;
    background-size : 1.8rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 200ms;

    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  @include media-breakpoint-down("md") {
    height: $screen-title-height;

    .title-container {
      height: 3.6rem;
      background-color: $brand-primary;
      color: $text-colour-inverse;
      text-align: center;
      font-size: medium;
      position: fixed;
      display: flex;
      align-items: center;
      left: 0;
      right: 0;
      top: 0;
      z-index: 999;

      .screen-title-text {
        flex: 1 1;
        display: block;
      }

      .action-icon, .action-space {
        display: block;

        &.always-display {
          -webkit-filter: invert(100%);

          &:hover {
            background-color: rgba(0,0,0,0.4);
          }
        }
      }

    }
  }
}