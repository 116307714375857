@import "../../../mixins";

.reflection-list-item {
  .content-title {
    font-weight: bold;
  }

  .content-date {
    font-size: small;
  }

  .content-container {
    display: flex;

    .reflection-icon {
      width: 1.2em;
      height: 1.2em;
      flex-shrink: 0;
      margin-right: 10px;
      border-radius: 4px;

      .reflection-icon-icon {
        width: 1em;
        height: 1em;
        margin: 0.05em;
        display: block;
        @include background-image-centre;
      }
    }

    .reflection-text {
      flex: 1 1;
    }

    .reflection-summary {
      max-height: 1.4em;
      overflow: hidden;
      font-size: small;
    }
  }
}