@import "../../../mixins";

.case-record-segment-navigation-link {

  .nav-icon {
    width: 2.4em;
    height: 2.4em;
    margin: 0.8em;
    @include background-image-centre;
    display: block;
  }

  .nav-content {
    flex: 1 1;

    .nav-title {
      font-size: small;
      font-weight: bold;
      text-transform: uppercase;
      display: block;
    }

    .nav-label {
      font-size: medium;
      display: block;
    }
  }

}