@import "../../../colours";

.case-record-filter-modal {

  .list-group {
    height: 400px;
    border: 1px solid $grey-xlight;
    overflow-y: auto;

    .list-group-item {
      border-top: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;

      &:last-child {
        border-bottom: 0 !important;
      }
    }
  }

}