@import "../../../../mixins";
@import "../../../../colours";

.case-record-filter-screen {

  .filter-item {
    display: flex;
    align-items: center;

    .filter-item-content {
      flex: 1 1;
    }

    .filter-item-action {
      width: 2.4rem;
      height: 2.4rem;
      display: block;
      @include background-image-centre;
      background-size: 1.8rem;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        border: 1px solid $grey-light;
      }
    }
  }

}