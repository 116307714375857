@import "../../../../mixins";

.registration-screen {

  .policy-document-item {
    display: flex;
    align-items: center;

    .icon {
      width: 2.4em;
      height: 2.4em;
      display: block;
      @include background-image-centre;
    }

    .content {
      flex: 1 1;
      margin-left: 10px;

      .document-title {
        font-size: large;
        font-weight: bold;
      }
    }
  }

}