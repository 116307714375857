@import "./tokyo";
@import "./mixins";
@import "./colours";

.app-screen {
  padding-bottom: 4.6rem; /* Pad away from Navigation Bar */
}

.full-width {
  width: 100%;
}

label {
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.body-locked {
  overflow: hidden !important;
}

.form-control {
  &.clickable {
    &:hover {
      background-color: $grey-xlight;
      cursor: pointer;
    }
  }
}

.file-hide {
  width: 1px;
  height: 1px;
  overflow:hidden;
  position: absolute;
  left: -9999px;
  top: -9999px;
}

.animate-screen-content {
  animation-name: app-screen-appear;
  animation-duration: 200ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;

  @keyframes app-screen-appear {
    0% { opacity : 0; transform: translateY(20vh);}
    100% { opacity : 1; transform: translateY(0); }
  }
}

/* BUTTON */
.btn {
  border-radius: 5px !important;
  transition: background-color 200ms;

  &.btn-primary {
    background-color: $brand-primary;
    border-color: darken($brand-primary, 0.2);

    &:hover, &:active {
      background-color: lighten($brand-primary, 4);
      border-color: darken($brand-primary, 0.2);
    }
  }
}

/* LISTS */
.list-group {
  h1, h2, h3, h4, h5 {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  //.list-group-item {
  //  &.clickable {
  //    cursor: pointer;
  //    background-color: $grey-xlight;
  //  }
  //}

  .list-indicator {
    width : 1.8em;
    height: 1.8em;
    display: inline-block;
    @include background-image-centre;
  }
}

/* BADGES */
.badge-row {
  display: block;

  .badge {
    border-radius: 999px;
    padding: 0.4em 1.2em;
  }

  .badge-icon {
    width: 1.2em;
    height: 1.2em;
    display: inline-block;
    @include background-image-centre;
    margin-right: 0.4em;
  }

  .badge-status {
    background-color: $yellowWarning;
    color: $text-colour;
    font-weight: bold;
  }
}

.badge-all-good {
  background: linear-gradient(270deg, $blueDark 0%, $blueLight 100%);
}

.badge-improve {
  background: linear-gradient(270deg, $orangeDark 0%, $orangeLight 100%);
}

/* PROGRESS */
.progress {
  .progress-bar {
    height: 100%;
  }
}

/* TABLES */
table {
  thead {
    border-radius: 5px;

    tr {
      border-bottom: none;
    }

    th {
      background-color: #eaeaea;
      border-bottom: none;

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  tbody {
    tr {
      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        td {
          &:first-child {
            border-bottom-left-radius: 5px;
          }
        }

        td {
          &:last-child {
            border-bottom-right-radius: 5px;
          }
        }
      }
    }
  }

  .sortable {
    cursor: pointer;
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 20px;
  }
}

/* SEGMENT BAR */
.segment-tab-bar {
  .segment-container {
    background-color: $white;
  }
}

/* LIST GROUP */
.list-group-item {
  &.clickable {
    cursor: pointer;

    &:hover:not(.active) {
      background-color: $grey-xlight;
    }

    &.active {
      &:not(:first-child) {
        border-top-color: #FFF;
      }

      &:hover {
        background-color: darken(#007bff, 3%);
      }
    }
  }
}

/* CARD */
.card {
  .card, &.outlined {
    border:1px solid $grey-light;
  }

  &.clickable {
    cursor: pointer;
  }

  .card-header {
    h1, h2, h3, h4 {
      margin: 0;
    }
  }

}

/* OFFCANVAS **/

.offcanvas {
  visibility: visible !important;
  animation-duration: 120ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  transform: translateX(0);
  z-index: 1061 !important;

  &.offcanvas-start {
    animation-name: offcanvas-appear-start !important;
  }

  &.offcanvas-end {
    animation-name: offcanvas-appear-end !important;
  }

  &.offcanvas-bottom {
    animation-name: offcanvas-appear-bottom !important;
  }

  &.offcanvas-top {
    animation-name: offcanvas-appear-top !important;
  }

  .offcanvas-footer {
    padding: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
}

.offcanvas-scrim {
  background: rgba(0, 0, 0, 0.6);
  animation-name: offcanvas-scrim-appear;
  animation-duration: 120ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1060;
}

.dismissing {
  .offcanvas {
    animation-direction: reverse !important;
    animation-play-state: running !important;
  }

  .offcanvas-scrim {
    animation-direction: reverse !important;
    animation-play-state: running !important;
  }
}

@keyframes offcanvas-scrim-appear {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes offcanvas-appear-start {
  0% {
    transform: translateX(-100%)
  }
  100% {
    transform: translateX(0)
  }
}

@keyframes offcanvas-appear-end {
  0% {
    transform: translateX(100%)
  }
  100% {
    transform: translateX(0)
  }
}

@keyframes offcanvas-appear-bottom {
  0% {
    transform: translateY(100%)
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes offcanvas-appear-top {
  0% {
    transform: translateY(-100%)
  }
  100% {
    transform: translateY(0);
  }
}
