@import "../../../colours";

.reflection-detail-screen {

  .info-widget {
    border-radius: 999px;
    background: $grey-xlight;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    font-size: large;
  }

  .section-info {
    background: $patientGrey;
    border-top: 3px solid $patientGreyAlt;
    color: $text-colour-inverse;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;

    .number {
      font-size: large;
      font-weight: bold;
    }

    .text {
      flex: 1 1;
      margin-left: 10px;
    }
  }

}