@import "../../../mixins";

.reflection-editor-modal {

  label {
    &.no-bold {
      font-weight: normal;
    }
  }

  .reflection-measure {
    .number {
      font-weight: bold;
    }

    .type-icon {
      width: 1.4em;
      height: 1.4em;
      display: inline-block;
      border-radius: 5px;

      .type-icon-icon {
        width: 1em;
        height: 1em;
        display: block;
        margin: 0.1em;
        @include background-image-centre;
      }

    }
  }

}