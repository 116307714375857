@import "../../../colours";
@import "../../../mixins";

$segment-number-width : 3.4em;

.case-record-landing-screen {

  .detail-header {
    h3 {
      font-weight: bold;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .detail-card {
      border: 5px solid $patientGreyAlt;
      border-left: 0;
      border-bottom: 0;
      border-right: 0;
      background-color: $patientGrey;
      color: $text-colour-inverse;
    }
  }

  .case-record-segment {
    padding: 0 0 0 $segment-number-width;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    .number-tag {
      width: $segment-number-width;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      font-size: large;

      .text {
        width: 100%;
        display: block;
        text-align: center;
        font-weight: bold;

        &.dark {
          color: $text-colour-inverse;
        }
      }
    }

    .segment-content {
      flex: 1 1;
      padding: 10px 15px;

      .segment-title {
        font-weight: bold;
        display: block;
      }
    }

    .indicator {
      width: 20px;
      height: 20px;
      @include background-image-centre;
      display: block;
    }

  }

}