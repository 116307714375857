@import "../../../colours";
@import "../../../mixins";

.login-screen {

  .logo-item {
    @include background-image-centre;
  }

  input[type=text], input[type=password] {
    text-align: center;
  }

  .small-logo {
    width: 120px;
    display:inline-block;
    @include background-image-centre;
  }

  .caption {
    font-size: small;
  }

  .secure-login-container {
    color: $text-colour-inverse;
    background: rgb(102,0,205);
    background: linear-gradient(145deg, $secureLoginStart 0%, $secureLoginEnd 100%);

    .secure-button {
      display: flex;
      align-items: center;
      font-weight: bold;

      .space {
        flex: 1 1;
      }

      .icon {
        height: 1.8em;
        width: 1.8em;
        display: inline-block;
        @include background-image-centre;
        margin-right: 10px;
      }
    }
  }

}