@import "../../colours";
@import "../../mixins";

.alert-modal {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: default;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: 1070;
  animation-name: modal-did-appear;
  animation-iteration-count: 1;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  &.dismissing {
    animation-direction: reverse !important;

    .alert-modal-dialog {
      animation-direction: reverse !important;
    }
  }

  .spacer {
    flex: 1 1;
  }

  .alert-modal-dialog {
    width: 100%;
    background: #FFF;
    border-radius: 20px;
    box-shadow: 0 0 20px $scrim-dark;
    text-align: center;
    padding: 20px;
    animation-name: modal-body-did-appear;
    animation-iteration-count: 1;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;

    .alert-modal-dialog-icon {
      width: 40px;
      height: 40px;
      margin: auto;
    }

    .alert-modal-dialog-title {
      font-size: large;
      font-weight: bold;
      display: block;
      margin-top: 10px;
    }

    .alert-modal-dialog-message, .alert-modal-dialog-input {
      font-size: medium;
      margin-top: 10px;
      display: block;
    }

    .alert-modal-dialog-buttons {
      display: block;
      margin-top: 15px;

      .alert-dialog-button {
        width: 100%;
        display: block;
        border-radius: 999px;
        border: 0;
        background-color: $grey-xlight;
        color: $text-colour;
        margin-top: 10px;
        cursor: pointer;
        transition: background-color;
        text-align: center;
        padding: 10px;

        &:first-child {
          margin-top: 0;
        }

        &:hover {
          background-color: #DFDFDF;
        }

        &.disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }

        &.success {
          background: $green-primary;
          color: #FFF;

          &:hover {
            background-color: $green-primary-alt;
          }
        }

        &.danger {
          background: $red-primary;
          color: #FFF;

          &:hover {
            background-color: $red-primary-alt;
          }
        }
      }
    }
  }

  @keyframes modal-body-did-appear {
    0% {
      opacity: 0;
      transform: scale(0.8)
    }
    80% {
      opacity: 1;
      transform: scale(1.05)
    }
    100% {
      transform: scale(1)
    }
  }

  @keyframes modal-did-appear {
    0% {
      opacity: 0
    }
    100% {
      opacity: 1
    }
  }

}

.modal {
  display: block !important;
  background-color: $scrim-dark;
  animation-name: generic-modal-background-did-appear;
  animation-duration: 200ms;
  overflow: auto !important;

  .close-button {
    width: 1.8rem;
    height: 1.8rem;
    @include background-image-centre;
    display: inline-block;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background-color: $grey-xlight;
    }
  }

  .modal-dialog {
    animation-name: generic-modal-did-appear;
    animation-duration: 200ms;
  }

  &.dismissing {
    animation-direction: reverse;

    .modal-dialog {
      animation-direction: reverse;
    }
  }
}

@keyframes generic-modal-background-did-appear {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes generic-modal-did-appear {
  0% {
    opacity: 0;
    transform: translateY(-20%)
  }
  30% {
    opacity: 0;
    transform: translateY(-20%)
  }
  90% {
    opacity: 1;
    transform: translateY(5%)
  }
  100% {
    opacity: 1;
    transform: translateY(0)
  }
}