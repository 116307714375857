@import "../../mixins";

.qi-header-bar {
  padding: 10px 0;

  .header-logo {
    width: 100%;
    max-width: 220px;
    height: 60px;
    @include background-image-centre;
    display: inline-block;
  }

}