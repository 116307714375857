@import "../../../colours";
@import "../../../mixins";

$section-heading-number-width: 3.4em;

$timeline-line-width : 4px;

.case-record-section-screen {

  .section-heading {
    width: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
    padding: 0 0 0 $section-heading-number-width;

    .number-tag {
      width: $section-heading-number-width;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;

      .text {
        width: 100%;
        display: block;
        text-align: center;
        font-size: large;
        font-weight: bold;

        &.dark {
          color: $text-colour-inverse;
        }
      }
    }

    .section-content {
      flex: 1 1;
      padding: 10px 15px;

      //.section-title {
      //  font-size: large;
      //  font-weight: bold;
      //}
    }

  }

  //.section-title {
  //  font-size: x-large;
  //  font-weight: bold;
  //}

  .section-subtitle {
    margin-bottom: 5px;
  }

  .section-value {
    font-size: large;
    font-weight: bold;
  }

  .section-item {
    display: flex;
  }

  .section-content, .section-item {
    //display: flex;
    padding: 0.5rem 0.5rem 1rem 0.3rem;

    &.section-item-success {
      background-color: mix(#FFF, $blueLight, 80%);
    }

    &.section-item-warning {
      background-color: mix(#FFF, $orangeLight, 80%);
    }

    &.section-item-error {
      background-color: #F6F6F6;
    }

    .section-title {
      font-size: medium;
      //font-weight: bold;
    }

    .number-tag {
      min-width: 3em;
      font-size: large;
      text-align: center;
      padding: 10px 0;

      .text {
        display: block;
      }

      .outcome-status {
        width: 25px;
        height: 25px;
        display: flex;
        border-radius: 5px;
        align-items: center;
        margin: auto;

        .outcome-icon {
          width: 15px;
          height: 15px;
          display: block;
          margin: auto;
          @include background-image-centre;
        }
      }
    }

    .item-content {
      flex : 1 1;
      margin-left: 10px;
    }
  }

  .segment-timeline {

    .timeline-item {
      width: 100%;
      display: flex;
      align-items: stretch;

      &:first-child {
        .timeline-item-line {
          .line {
            height: 51%;
            transform: translateY(100%);
          }
        }
      }

      &:last-child {
        .timeline-item-line {
          .line {
            height: 50%;
          }
        }
      }

      .timeline-item-time {
        width: 4.8em;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        padding-right: 10px;

        .text {
          width: 100%;
          display: block;
          text-align: right;
          font-weight: bold;
          font-size: small;
        }
      }

      .timeline-item-line {
        width : $timeline-line-width * 3;
        display: block;
        flex-shrink: 0;
        position: relative;

        .line {
          width: $timeline-line-width;
          height: 100%;
          background-color: $grey-primary;
          margin: auto;

          &.fade-centre {
            background: linear-gradient(0deg, rgba($grey-primary, 1) 0%, transparentize($grey-primary, 1) 50%, rgba($grey-primary, 1) 100%);
          }
        }

        .indicator {
          width: $timeline-line-width * 3;
          height: $timeline-line-width * 3;
          border-radius: 50%;
          border: 1px solid $grey-primary;
          background-color: $grey-primary;
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          margin: auto;
          transform: translateY(-50%);

          &.small {
            width: $timeline-line-width * 2;
            height: $timeline-line-width * 2;
          }

          &.active {
            background-color: $yellowWarning;
          }
        }
      }

      .timeline-item-body {
        display: block;
        flex: 1 1;
        margin: 10px 0 10px 15px;
        position: relative;

        .triangle {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7.5px 10px 7.5px 0;
          border-color: transparent #000 transparent transparent;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%) translateX(-100%);
        }

        &.timeline-header-item {
          background: $background-primary;
          border-radius: 5px;
          box-shadow: 0 0 5px $shadow-faint;
          text-align: center;
          font-size: small;
          font-weight: bold;
          padding: 2px;
        }

        &.spacer {
          height: 1.8em;
        }

        &.badge-all-good, &.badge-improve {
          display: flex;
          align-items: center;
          border-radius: 5px;
          color: $text-colour-inverse;

          .item-content {
            flex: 1 1;
            padding: 5px;

            .title {
              font-weight: bold;
            }

            .subtitle {

            }
          }

          .action {
            width: 1.4em;
            height: 1.4em;
            @include background-image-centre;
            flex-shrink: 0;
            margin-right: 10px;
            cursor: pointer;
          }
        }

        &.badge-all-good {
          .triangle {
            border-color: transparent $blueLight transparent transparent;
          }
        }

        &.badge-improve {
          .triangle {
            border-color: transparent $orangeLight transparent transparent;
          }
        }
      }

    }

  }

}