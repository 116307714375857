.loading-spinner {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;

  &.inline {
    width: auto;
    display: inline-block;
  }

  &.small {
    .spinner-container {
      width: 40px !important;
      height: 40px !important;

      .spinner-spin {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }

  &.font-small {
    .spinner-container {
      width: 1.4em;
      height: 1.4em;
    }

    .spinner-spin {
      width: 1.4em;
      height: 1.4em;
    }
  }

  .spinner-container {
    width: 120px;
    height: 120px;
    display: block;
    position: relative;
    margin: auto;

    .spinner-spin {
      position: absolute;
      inset: 0;
      display: block;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      animation-name: loading-spinner-animate;
      animation-timing-function: ease-in-out;
      animation-duration: 1200ms;
      animation-iteration-count: infinite;

      &.after-image {
        opacity: 0.2;
        animation-delay: 80ms;
      }
    }

  }



  @keyframes loading-spinner-animate {
    0% { transform: rotate(0deg) }
    30% { transform: rotate(-90deg) }
    100% { transform: rotate(360deg) }
  }

}