@import "../../../colours";
@import "../../../mixins";

.organisation-picker-modal {

  .organisation-item {
    display: flex;
    align-items: center;

    .icon {
      width: 60px;
      height: 60px;
      display: block;
      @include background-image-centre;
      border-radius: 5px;
      border: 1px solid $grey-light;
      background-color: $white;
    }

    .content {
      flex: 1 1;
      margin-left: 10px;
    }

  }

}