@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,800;1,400;1,500;1,800&display=swap');

@import "./colours";
@import "./dimensions";

body {
  font-family: 'Open Sans', sans-serif;
}

button, .btn {
  border-radius: $pill-border-radius;
  padding: $pill-padding-vertical $pill-padding-horizontal;
  transition: none;

  &:hover {
    border-color: $grey-light !important;
  }

  &.btn-primary {
    background: $blue-primary;
    color: $text-colour-inverse;

    &:hover {
      background: $blue-primary-alt;
      border-color: $blue-primary-alt;
    }
  }

  &.btn-sm {
    padding: ($pill-padding-vertical / 2) ($pill-padding-vertical / 1.4);
  }
}

/*
    TABLE
 */

.table-container {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;

  th {
    font-weight: bold;
    padding: 8px;
    background: $grey-light;
    text-align: left;
  }

  tr {
    border-bottom: 1px solid $grey-light;

    &:nth-child(even) {
      background: #F8F8F8;
    }

    td {
      padding: 8px;

      p {
        margin: 0;
      }
    }
  }

  &.clickable {
    tr {
      cursor : pointer;

      &:hover {
        background-color: $grey-xlight;
      }
    }
  }

  .empty-message {
    text-align: center;
  }

}

/*
    Navigation List
 */
.tokyo-navigation-list {
  width: 100%;
  display: block;

  .navigation-filter {
    display: block;
    margin: 10px 0;
    box-sizing: border-box;

    input {
      width: 100%;
      display: block;
      border-radius: $pill-border-radius;
      border: 1px solid $grey-light;
      padding: $pill-padding-vertical $pill-padding-horizontal;
      color: $text-colour;
      box-sizing: border-box;
    }
  }

  .navigation-heading {
    display: block;
    font-weight: bold;
    padding: $pill-padding-vertical $pill-padding-horizontal;
    cursor: default;
    color: $blue-primary-alt;
    font-size: large;

    &.filtered {
      display: none;
    }
  }

  .navigation-item {
    display: block;
    font-weight: bold;
    padding: $pill-padding-vertical $pill-padding-horizontal;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: $pill-border-radius;
    text-align: left;
    cursor: pointer;
    color: $text-colour;
    text-decoration: none;

    &:hover {
      border-color: $grey-primary;
    }

    &:last-child {
      margin-bottom: 40px;
    }

    &.active {
      background: $blue-primary;
      color: $text-colour-inverse;
    }

    &.filtered {
      display: none;
    }
  }

  .navigation-heading, .navigation-item {
    margin-top: 5px;

    &:nth-child(1) {
      margin-top: 0;
    }
  }

}