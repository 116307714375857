@import "../../colours";
@import "../../mixins";

$navigation-bar-height: 3.6rem;
$navigation-bar-icon-size: 1.2rem;
$navigation-bar-title-height: 1.4rem;

.qi-navigation-bar {
  height: $navigation-bar-height + 0.8rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $background-primary;
  box-shadow: 0 0 5px $shadow-faint;

  .navigation-item {
    height: $navigation-bar-height;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;
    transition: background-color 200ms;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: $text-colour;
    position: relative;

    .spacer {
      flex: 1 1;
    }

    &.active {
      color: $blue-primary;
      font-weight: bold;
    }

    &:hover {
      background-color: $grey-xlight;
    }

    .navigation-item-icon {
      width: $navigation-bar-icon-size;
      height: $navigation-bar-icon-size;
      display: inline-block;
      @include background-image-centre;
      position: relative;

      .badge {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(90%) translateY(-30%);
      }
    }

    .navigation-item-title {
      max-height: $navigation-bar-height;
      overflow: hidden;
      text-align: center;
      margin-top: 0.2rem;
    }
  }

}