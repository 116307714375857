@import "../../../../colours";
@import "../../../../mixins";

.case-record-structure-component {

  .list-group-item {
    padding: 0;

    .segment-content {
      padding: .75rem 1.25rem;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        background-color: $grey-light;
      }

      .segment-content-number {
        font-size: large;
        font-weight: bold;
        padding: 0 0.75rem 0 0;
      }

      .segment-content-title {
        flex: 1 1;
      }

      .segment-content-actions {
        .action, .indicator {
          width: 2.4rem;
          height: 2.4rem;
          border-radius: 5px;
          @include background-image-centre;
          display: inline-block;
        }

        .indicator {
          transition: transform 200ms;

          &.expanded {
            transform: rotate(90deg);
          }
        }

        .action {
          border: 1px solid $grey-primary;
          background-color: $white;
          background-size: 1.4rem;
        }
      }
    }

    .list-group {
      margin: 5px 1.25rem;
    }

  }

}
