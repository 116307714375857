@import "../../colours";

$switch-height : 1.4em;

.qi-switch {
  height: ($switch-height * 1.2);
  width: ($switch-height * 2) * 1.2;
  border-radius: 999px;
  overflow: hidden;
  border: 1px solid $grey-light;
  position: relative;

  .check-hide {
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
  }

  .handle {
    width: $switch-height;
    height: $switch-height;
    border-radius: 999px;
    overflow: hidden;
    display: block;
    position: absolute;
    left : ($switch-height * 0.2);
    top: ($switch-height * 0.08);
    margin-left: 0;
    transition: margin-left 100ms;

    .handle-block {
      width: $switch-height;
      height: $switch-height;
      display: block;
      position: relative;

      .block-colour {
        border-radius: 0 !important;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 1;
        transition: opacity 100ms;
      }
    }
  }

  &.active {
    .handle {
      margin-left: $switch-height;

      .handle-block {
        .block-colour:last-child {
          opacity: 0;
        }
      }
    }
  }

}